'use strict';

class PrintTopPageController {
  constructor($scope, $state) {
    this.$scope = $scope;
    this.overview = $scope.overview;
  }

  $onInit() {
    // console.log('overview: ', this.overview);
  }
}

angular.module('windmanagerApp')
  .controller('PrintTopPageController', PrintTopPageController);
